<template>
  <div>
    <router-view />
  </div>
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body{
  // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
li {
  list-style-type: none;
}

:root {
  min-height: 100vh;
  color: rgba(17, 17, 17, 1);
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Toast,Icon  } from 'vant';
import 'vant/lib/index.css';

const app = createApp(App)

app.use(store);
app.use(Toast);
app.use(Icon);
app.use(router);
app.mount('#app');
